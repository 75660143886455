<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                      <v-dialog
                          v-model="modalForm"
                          persistent
                          max-width="600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              color="success"
                              dark
                              v-bind="attrs"
                              v-on="on"
                          >
                            Add Car Park Override
                          </v-btn>
                        </template>
                        <ModalForm v-on:closeModal="modalForm = false"></ModalForm>
                      </v-dialog>
                    </div>
                </v-card-title>
                <v-card-title>
                  <strong><v-icon x-large>mdi-clipboard-edit-outline</v-icon></strong> Car Park Overrides
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    v-model="selected"
                    :search="search"
                    :headers="headers"
                    :items="carParkOverrides"
                    item-key="name"
                    show-select
                    class="elevation-1 table-one"
                    multi-sort
                >
                    <template v-slot:item.name="{item}">
                        <div class="d-flex align-center">
                            <p class="ma-0 font-weight-medium">
                                {{ item.name }}
                            </p>
                        </div>
                    </template>
                  <template v-slot:item.occupancy="{item}">
                    <div class="d-flex">

                        <template>
                        <v-progress-linear
                            :value="item.occupancy"
                            width="50"
                            height="10"
                            striped
                            color="deep-orange"
                        />
                        </template>
                    </div>
                  </template>
                    <template v-slot:item.action="{item}">
                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.badge="{item}">
                        <template v-if="item.badge === 'Active'">
                            <v-chip
                                class=""
                                color="success"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip
                                class=""
                                color="danger"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>
                    </template>
                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>

import ModalForm from '../../modalForms/CreateCarParkOverride'

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Car Park Overrides'
    },
  components: {
    ModalForm
  },

  mounted() {
    this.getCarParks();
  },
  methods: {
    getCarParks() {
      this.$store.dispatch('car-park-overrides/find', {});
      this.carParkOverrides = this.$store.getters['car-park-overrides/list'];
    },
    save(date) {
      this.$refs.menu.save(date)
    }
  },

    data() {
        return {
          modalForm: false,
            search: '',
            selected: [],
            headers: [
              {
                text: 'Spaces',
                sortable: true,
                value: 'name',
                align: 'left',
              },
              {
                text: 'Date From',
                sortable: true,
                value: 'date_from',
                align: 'left',
              },
              {
                text: 'Date To',
                sortable: true,
                value: 'date_to',
                align: 'left',
              },
              {
                text: 'Spaces',
                sortable: true,
                value: 'spaces',
                align: 'left',
              },
              {
                text: 'Actions',
                value: 'action',
              }
            ],
            carParkOverrides: []
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
