<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">Create CarParkOverride</div>
      </v-card-title>
      <v-card-text>
        <template>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-select
                v-model="car_park_id"
                :items="carParks"
                item-text="name"
                item-value="id"
                label="Select Car Park"
            ></v-select>
            <v-text-field label="Spaces" v-model="spaces" required></v-text-field>
            <v-dialog
                ref="dialog"
                v-model="date_from"
                :return-value.sync="date_from"
                persistent
                width="290px"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                    label="Date From"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date_from" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$emit('closeModal')">
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date_from)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date_to"
                persistent
                width="290px"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                    v-model="date_to"
                    label="Date To"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date_to" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$emit('closeModal')">
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date_to)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closeModal')">Cancel</v-btn>
        <v-btn @click="reset">Reset</v-btn>
        <v-btn @click="submit" :disabled="!valid">
              Save
            </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Textarea'
  },
  data: () => ({
    valid: true,
    modal: false,
    autoGrow: false,
    autofocus: true,
    clearable: false,
    counter: 0,
    filled: false,
    flat: false,
    hint: '',
    label: '',
    loading: false,
    model: "I'm a textarea.",

    noResize: false,
    outlined: false,
    persistentHint: false,
    placeholder: '',
    rounded: false,
    rowHeight: 24,
    rows: 1,
    shaped: false,
    singleLine: false,
    solo: false,
    carParks: [
      {
        name: 'A/B Upper'
      },
      {
        name: 'A/B Lower'
      }
    ],
  })
}
</script>

